import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AIImageUpscaler from './sections/AIImageUpscaler';
import styles from './ImageToUpscale.module.css';

function ImageToUpscale() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOverlayClick = () => {
    setIsMenuOpen(false);
  };

  const handleNavigation = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className={styles.textToPicContainer}>
      {/* Page Header */}
      <div className={styles.pageHeader}>
        <button 
          className={`${styles.hamburgerBtn} ${isMenuOpen ? styles.open : ''}`}
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>

      {/* Sidebar with mobile responsive classes */}
      <nav className={`${styles.sidebar} ${isMenuOpen ? styles.open : ''}`}>
        <div className={styles.mobileHeader}>
          Menu
        </div>
        <ul>
          <li>
            <Link to="/" onClick={handleNavigation}>
              <i className={styles.icon}>🏠</i>
              <span className={styles.boldText}>Home</span>
            </Link>
          </li>
          <li>
            <Link to="/text-to-pic" onClick={handleNavigation}>
              <i className={styles.icon}>🖼️</i>
              <span className={styles.boldText}>Text to Pic</span>
            </Link>
          </li>
          <li className={styles.active}>
            <Link to="/image-upscaler" onClick={handleNavigation}>
              <i className={styles.icon}>⬆️</i>
              <span className={styles.boldText}>Image Upscaler</span>
            </Link>
          </li>
          <li>
            <Link to="/tokens" onClick={handleNavigation}>
              <i className={styles.icon}>🥏</i>
              <span className={styles.boldText}>Tokens</span>
            </Link>
          </li>
          <li>
            <Link to="/user-settings" onClick={handleNavigation}>
              <i className={styles.icon}>⚙️</i>
              <span className={styles.boldText}>User Settings</span>
            </Link>
          </li>
        </ul>
      </nav>

      <div 
        className={`${styles.overlay} ${isMenuOpen ? styles.open : ''}`} 
        onClick={handleOverlayClick}
      />

      <main className={styles.mainContent}>
        <AIImageUpscaler />
      </main>
    </div>
  );
}

export default ImageToUpscale;