import React, { useState, useEffect } from 'react';
import styles from './GallerySection.module.css';

import image1 from './Images/1.png';
import image2 from './Images/2.png';
import image3 from './Images/3.png';
import image4 from './Images/4.png';
import image5 from './Images/5.png';
import image6 from './Images/6.png';
import image7 from './Images/7.png';
import image8 from './Images/8.png';
import image9 from './Images/9.png';
import image10 from './Images/10.png';
import image11 from './Images/11.png';
import image12 from './Images/12.png';

const allImages = [
  image3, image2, image8, image10, image4, image6,
  image12, image7, image5, image9, image11, image1,
];

function GallerySection() {
  const [displayImages, setDisplayImages] = useState(allImages);
  const [isMobile, setIsMobile] = useState(false);

  // Function to shuffle array and get first 6 items
  const getRandomImages = () => {
    return [...allImages]
      .sort(() => Math.random() - 0.5)
      .slice(0, 6);
  };

  useEffect(() => {
    // Function to handle resize
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      
      // Update images if mobile
      if (mobile) {
        setDisplayImages(getRandomImages());
      } else {
        setDisplayImages(allImages);
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty dependency array means this runs once on mount

  return (
    <section className={styles.gallerySection}>
      <h2></h2>
      <div className={styles.imageGrid}>
        {displayImages.map((image, index) => (
          <img key={index} src={image} alt={`AI-generated image ${index + 1}`} />
        ))}
      </div>
    </section>
  );
}

export default GallerySection;