import { useEffect } from 'react';
import { auth, db } from "../config/firebase-config";
import { doc, updateDoc, getDoc } from "firebase/firestore";

function AuthStateHandler() {
  useEffect(() => {
    const checkAndUpdateEmailVerification = async (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists() && !userDoc.data().emailVerified) {
          await user.reload();
          if (user.emailVerified) {
            await updateDoc(userDocRef, { emailVerified: true });
          }
        }
      }
    };

    const unsubscribe = auth.onAuthStateChanged(checkAndUpdateEmailVerification);
    return () => unsubscribe();
  }, []);

  return null;
}

export default AuthStateHandler;