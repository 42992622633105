import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { auth, db } from "../../config/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import styles from './PricingSection.module.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const products = [
 { id: process.env.REACT_APP_STRIPE_PRICE_BASIC, name: '100 Tokens', price: 1.00, features: ['100 AI Photos', 'Upscale Photos', 'No expiration'] },
 { id: process.env.REACT_APP_STRIPE_PRICE_PREMIUM, name: '1200 Tokens', price: 10.00, features: ['1,200 AI Photos', 'Upscale Photos', 'No expiration'] },
 { id: process.env.REACT_APP_STRIPE_PRICE_BUSINESS, name: '2800 Tokens', price: 20.00, features: ['2,800 AI Photos', 'Upscale Photos', 'No expiration'] }
];

const Products = () => {
 const [user, setUser] = useState(null);
 const [userProfile, setUserProfile] = useState(null);
 const [pricingType, setPricingType] = useState('tokens');
 const [isLoading, setIsLoading] = useState(true);

 useEffect(() => {
   const unsubscribe = auth.onAuthStateChanged(async (user) => {
     setUser(user);
     if (user) {
       try {
         const userDocRef = doc(db, "users", user.uid);
         const userDoc = await getDoc(userDocRef);
         if (userDoc.exists()) {
           setUserProfile(userDoc.data());
         }
       } catch (error) {
         console.error("Error fetching user profile:", error);
       }
     } else {
       setUserProfile(null);
     }
     setIsLoading(false);
   });
   return () => unsubscribe();
 }, []);

 const canPurchase = userProfile && userProfile.emailVerified && !userProfile.isDispute && !userProfile.isFraudulent;

 const handleCheckout = async (priceId) => {
   try {
     if (!user) {
       console.error('User not logged in');
       return;
     }

     if (!userProfile?.emailVerified) {
       console.error('Email not verified');
       alert('Please verify your email before making a purchase.');
       return;
     }

     if (userProfile?.isDispute || userProfile?.isFraudulent) {
       console.error('User has an open dispute or fraud warning');
       alert('You cannot make a purchase. Please contact support.');
       return;
     }

     const stripe = await stripePromise;

     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify({ 
         priceId,
         userId: user.uid,
         userEmail: user.email
       }),
     });

     if (!response.ok) {
       throw new Error('Network response was not ok');
     }

     const session = await response.json();

     const result = await stripe.redirectToCheckout({
       sessionId: session.id,
     });

     if (result.error) {
       console.error('Stripe checkout error:', result.error);
     }
   } catch (error) {
     console.error('Error in handleCheckout:', error);
   }
 };

 const getPurchaseMessage = () => {
   if (!user) {
     return null;  // Show no message if no user is logged in
   }
   if (!userProfile?.emailVerified) {
     return <p style={{color: '#FF4500'}}>Please verify your email to make purchases.</p>;
   }
   if (userProfile?.isDispute) {
     return <p style={{color: 'red'}}>Purchase unavailable due to open dispute. Please contact support.</p>;
   }
   if (userProfile?.isFraudulent) {
     return <p style={{color: 'red'}}>Purchase unavailable due to fraud warning. Please contact support.</p>;
   }
   return null;
 };

 if (isLoading) {
   return null;
 }

 return (
   <section className={styles.pricingSection}>
     <h2>Plans & Pricing</h2>
     <div className={styles.pricingToggle}>
       <button 
         className={pricingType === 'tokens' ? styles.active : ''} 
         onClick={() => setPricingType('tokens')}
       >
         Tokens
       </button>
     </div>
     <div className={styles.planGrid}>
       {products.map((product, index) => (
         <div key={index} className={styles.planCard}>
           <h3>{product.name}</h3>
           <p className={styles.price}>
             ${product.price.toFixed(2)}
             <span>/package</span>
           </p>
           <ul>
             {product.features.map((feature, i) => (
               <li key={i}>{feature}</li>
             ))}
           </ul>
           <button 
             className={styles.subscribeButton} 
             onClick={() => {
              if (!user) {
                window.scrollTo({ top: 0, behavior: 'smooth' });  // Smooth scroll to top
                return;
              }
              handleCheckout(product.id);  // Proceed with checkout if logged in
              }}
              disabled={isLoading || (user && !canPurchase)}  // Only disable if user exists but can't purchase
              >
                Buy Tokens
              </button>
           {!isLoading && getPurchaseMessage()}
         </div>
       ))}
     </div>
   </section>
 );
};

export default Products;