import React from 'react';
import './WaveTransition2.module.css';

const WaveTransition2 = () => {
  return (
    <div className="wave-container2">
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 1440 320" 
        preserveAspectRatio="none"
        style={{transform: 'rotate(180deg)'}}
      >
        <path fill="url(#gradient)" fillOpacity="1" d="M0,64L48,80C96,96,192,128,288,128C384,128,480,96,576,90.7C672,85,768,107,864,128C960,149,1056,171,1152,165.3C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
        </path>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{stopColor: 'rgb(0,0,0)'}} />
          <stop offset="55%" style={{stopColor: 'rgb(0,0,0)'}} />
          <stop offset="60%" style={{stopColor: 'rgba(0,0,0,0.98)'}} />
          <stop offset="65%" style={{stopColor: 'rgba(5,5,5,0.95)'}} />
          <stop offset="70%" style={{stopColor: 'rgba(10,10,10,0.90)'}} />
          <stop offset="75%" style={{stopColor: 'rgba(15,15,15,0.85)'}} />
          <stop offset="80%" style={{stopColor: 'rgba(20,20,20,0.75)'}} />
          <stop offset="85%" style={{stopColor: 'rgba(25,25,25,0.65)'}} />
          <stop offset="90%" style={{stopColor: 'rgba(30,30,30,0.55)'}} />
          <stop offset="95%" style={{stopColor: 'rgba(35,35,35,0.45)'}} />
          <stop offset="97%" style={{stopColor: 'rgba(40,40,40,0.30)'}} />
          <stop offset="100%" style={{stopColor: 'rgba(45,45,45,0)'}} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default WaveTransition2;