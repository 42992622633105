import React from 'react';
import styles from './CallToAction.module.css';

function CallToAction() {
  return (
    <section className={styles.ctaSection}>
      <div className={styles.ctaContent}>
        <h2>Start Creating Amazing Photos Today</h2>
        <p>Join thousands of people who are revolutionizing their content with AI.</p>
        <button className={styles.ctaButton} onClick={() => window.scrollTo(0, 0)}>
          Create Your Pics Now
        </button>
      </div>
      <div className={styles.ctaImage}>
        {/* Add an eye-catching AI-generated image here */}
      </div>
    </section>
  );
}

export default CallToAction;