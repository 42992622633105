import React, { useState, useEffect } from 'react';
import { auth, db } from "../../config/firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import styles from './AIImageGenerator.module.css';

const AIImageGenerator = () => {
  const [prompt, setPrompt] = useState('');
  const [aspectRatio, setAspectRatio] = useState('1:1');
  const [numOutputs, setNumOutputs] = useState(1);
  const [quality, setQuality] = useState('med');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [userTokens, setUserTokens] = useState(0);
  const [userFlags, setUserFlags] = useState({ 
    isDispute: false, 
    isFraudulent: false, 
    emailVerified: false 
  });
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserTokens(userData.tokens);
          setUserFlags({
            isDispute: userData.isDispute || false,
            isFraudulent: userData.isFraudulent || false,
            emailVerified: userData.emailVerified || false
          });
        }
      }
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return null;
  }

  const getAccountStatusMessage = () => {
    if (userFlags.isFraudulent) {
      return <p className={styles.error}>Account suspended due to fraud warning. Please contact support.</p>;
    }
    if (userFlags.isDispute) {
      return <p className={styles.error}>Account suspended due to open dispute. Please contact support.</p>;
    }
    if (!userFlags.emailVerified) {
      return <p className={styles.error}>Please verify email before use.</p>;
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setError("Please log in to generate images.");
      return;
    }
    if (userFlags.isDispute || userFlags.isFraudulent) {
      setError("Your account has been suspended. Please contact support.");
      return;
    }
    if (userTokens === 0) {
      navigate('/tokens');
      return;
    }
    if (userTokens < numOutputs) {
      setError("Not enough tokens. Please purchase more.");
      return;
    }

    setError(null);

    setIsProcessing(true);

    const qualityMap = {
      'low': 33,
      'med': 66,
      'high': 100
    };

    const data = {
      userId: user.uid,
      prompt,
      aspectRatio,
      numOutputs,
      quality,
      goFast: true,
      outputFormat: 'webp',
      outputQuality: qualityMap[quality]
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/generate-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      
      const result = await response.json();
      
      if (result.success) {
        const generatedUrls = result.output;
        setGeneratedImages(generatedUrls);
        const actualGeneratedCount = generatedUrls.length;
        await updateUserTokens(user.uid, actualGeneratedCount);
      } else {
        setError(result.error || 'An error occurred');
      }
    } catch (error) {
      setError('Failed to generate image');
    } finally {
      setIsProcessing(false);
    }
  };

  const updateUserTokens = async (userId, tokensUsed) => {
    const userDocRef = doc(db, "users", userId);
    const newTokenBalance = userTokens - tokensUsed;
    await updateDoc(userDocRef, { tokens: newTokenBalance });
    setUserTokens(newTokenBalance);
  };

  const handleDownload = (url, index) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `generated-image-${index + 1}.webp`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {});
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Image Generator</h1>
        <div className={styles.tokenBalance}>
          {user ? (
            <>
              <p>Token Balance: {userTokens}</p>
              {getAccountStatusMessage()}
            </>
          ) : (
            <p>Please log in to use the generator</p>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.promptSection}>
          <label htmlFor="prompt">Prompt</label>
          <textarea 
            id="prompt" 
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt here..." 
            required
            disabled={userFlags.isDispute || userFlags.isFraudulent || !userFlags.emailVerified}
          />
        </div>
        
        <div className={styles.controlsRow}>
          <div className={styles.controlItem}>
            <label htmlFor="aspectRatio">Aspect Ratio</label>
            <select 
              id="aspectRatio" 
              value={aspectRatio}
              onChange={(e) => setAspectRatio(e.target.value)}
              disabled={userFlags.isDispute || userFlags.isFraudulent || !userFlags.emailVerified}
            >
              <option value="1:1">1:1</option>
              <option value="9:16">9:16</option>
              <option value="16:9">16:9</option>
              <option value="1:1">Square</option>
              <option value="9:16">Mobile</option>
              <option value="16:9">Widescreen</option>
            </select>
          </div>
          
          <div className={styles.controlItem}>
            <label>Number of Images</label>
            <div className={styles.numOutputs} id="numOutputs">
              {[1, 2, 3, 4].map(num => (
                <button
                  key={num}
                  type="button"
                  className={numOutputs === num ? styles.active : ''}
                  onClick={() => setNumOutputs(num)}
                  disabled={num > userTokens || userFlags.isDispute || userFlags.isFraudulent || !userFlags.emailVerified}
                >
                  {num}
                </button>
              ))}
            </div>
          </div>
          
          <div className={styles.controlItem}>
            <label>Quality</label>
            <div className={styles.quality} id="quality">
              {['low', 'med', 'high'].map(q => (
                <button
                  key={q}
                  type="button"
                  className={quality === q ? styles.active : ''}
                  onClick={() => setQuality(q)}
                  disabled={userFlags.isDispute || userFlags.isFraudulent || !userFlags.emailVerified}
                >
                  {q.charAt(0).toUpperCase() + q.slice(1)}
                </button>
              ))}
            </div>
          </div>
        </div>
        
        <button 
          type="submit" 
          disabled={!user || userFlags.isDispute || userFlags.isFraudulent || !userFlags.emailVerified}
          className={styles.submitButton}
        >
          Generate
        </button>
      </form>

      {isProcessing && <p>Generating images...</p>}
      {error && <p className={styles.error}>{error}</p>}
      
      <div className={styles.result}>
        <h2>Generated Images</h2>
        <div className={styles.imageGrid}>
          {generatedImages.map((url, index) => (
            <div 
              key={index} 
              className={styles.imageContainer}
              onClick={() => handleDownload(url, index)}
              style={{ cursor: 'pointer' }}
            >
              <img src={url} alt={`Generated image ${index + 1}`} />
              <button 
                className={styles.downloadButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownload(url, index);
                }}
              >
                Download
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AIImageGenerator;