// src/components/HomePage.js
import React from 'react';
import styles from './HomePage.module.css';
import HeroSection from './sections/HeroSection';
import WaveTransition from './sections/WaveTransition';
import GallerySection from './sections/GallerySection';
import WaveTransition2 from './sections/WaveTransition2';
import FeaturesSection from './sections/FeaturesSection';
import PricingSection from './sections/PricingSection';
import FAQSection from './sections/FAQSection';
import CallToAction from './sections/CallToAction';


function HomePage() {
  return (
    <div className={styles.homePage}>
      <HeroSection />
      <WaveTransition />
      <GallerySection />
      <WaveTransition2 /> 
      <FeaturesSection />
      <PricingSection />
      <FAQSection />
      <CallToAction />

    </div>
  );
}

export default HomePage;