// src/components/sections/FAQSection.js
import React, { useState } from 'react';
import styles from './FAQSection.module.css';

const faqs = [
  { question: 'What is Text to Pic AI?', answer: 'Text to Pic AI is a web application that uses artificial intelligence to generate high-quality photos based on text descriptions.' },
  { question: 'How does Text to Pic AI work?', answer: 'You input a text description of the image you want, and we generate a photo matching that description.' },
  { question: 'What types of images can I create?', answer: 'You can create a wide variety of images in almost any style, from realistic photos to artistic renders.' },
  { question: 'In what sizes are the generated photos available?', answer: 'Photos are available in square, mobile, and widescreen sizes to suit various needs.' },
  { question: 'How fast can I create images using Text to Pic AI?', answer: 'Our AI can generate high-quality photos in seconds, significantly speeding up your content creation process.' },
  { question: 'Is Text to Pic AI cost-effective?', answer: 'Yes, using Text to Pic AI can help you save money on content creation for personal use or your business.' },
  { question: 'Can I use the generated images for commercial purposes?', answer: 'As long as there are no trademarks, logos, etc. Then you can use the images for various purposes including social media, school projects, personal and business use.' },
  { question: 'How does Text to Pic AI compare to hiring a photographer or using stock photos?', answer: 'Text to Pic AI offers a faster and often more cost-effective solution, allowing you to create custom images instantly without the need for photoshoots or licensing fees.' },
  { question: 'Do I need any technical skills to use Text to Pic AI?', answer: 'No, our user-friendly interface is designed for users of all skill levels. Whether you\'re a professional designer or a casual user, you can easily create AI-generated images with our tool.' },
  { question: 'What pricing options do you offer?', answer: 'We offer token packages.' },
  { question: 'How do tokens work?', answer: 'Tokens can be purchased and used to generate photos. Each token allows you to create 1 photo. For example, 100 tokens will let you generate 100 photos.' },
  { question: 'Do tokens expire?', answer: 'No, tokens do not expire. Any unused tokens will continue to accumulate and roll over into the next month. For instance, if you buy the 1200 tokens pack and only use 200 tokens, the remaining 1000 tokens will be available for use in the following months.' }

  // Add more FAQs
];

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`${styles.faqItem} ${isOpen ? styles.open : ''}`}>
      <div 
        className={styles.faqQuestion} 
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={styles.faqQuestionText}>{question}</span>
        <span className={styles.faqToggle}>{isOpen ? '−' : '+'}</span>
      </div>
      {isOpen && <div className={styles.faqAnswer}>{answer}</div>}
    </div>
  );
}

function FAQSection() {
  return (
    <section className={styles.faqSection}>
      <h2 className={styles.faqTitle}>FAQ</h2>
      <div className={styles.faqList}>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </section>
  );
}

export default FAQSection;