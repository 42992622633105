import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { auth, googleProvider, db } from "../../config/firebase-config";
// Added sendEmailVerification to imports
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import styles from './HeroSection.module.css';

function HeroSection() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const navigate = useNavigate();

  const colors = ['#00b2ca', 'green'];
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const iconColor = colors[currentColorIndex];

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

  const createStripeCustomer = async (userId, email) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-stripe-customer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, email }),
      });

      if (!response.ok) {
        throw new Error('Failed to create Stripe customer');
      }

      const { customerId } = await response.json();
      return customerId;
    } catch (error) {
      console.error("Error creating Stripe customer:", error);
      throw error;
    }
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    if (isLoggedIn) {
      navigate('/text-to-pic');
      return;
    }
    setError(null);
    setIsLoading(true);

    if (isResettingPassword) {
      try {
        await sendPasswordResetEmail(auth, email);
        setError("Password reset email sent. Please check your inbox.");
        setIsResettingPassword(false);
      } catch (error) {
        console.error("Error sending password reset email:", error);
        setError("Failed to send password reset email. Please try again.");
      } finally {
        setIsLoading(false);
      }
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("User signed in successfully");
      navigate('/text-to-pic');
    } catch (error) {
      if (error.code === 'auth/invalid-credential' || error.code === 'auth/user-not-found') {
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
          
          const customerId = await createStripeCustomer(user.uid, user.email);
          
          const userData = {
            email: user.email,
            stripeCustomerId: customerId,
            tokens: 0,
            isDispute: false,
            emailVerified: user.emailVerified  // Added for email verification
          };
          
          await setDoc(doc(db, "users", user.uid), userData);
          await sendEmailVerification(user);    // Added for email verification
          console.log("New user account created and signed in, verification email sent");
          navigate('/text-to-pic');
        } catch (signUpError) {
          console.error("Error creating new account:", signUpError);
          if (signUpError.code === 'auth/email-already-in-use') {
            setError("Please try signing in or reset your password.");
          } else {
            setError(signUpError.message);
          }
        }
      } else {
        console.error("Error signing in:", error);
        setError("Invalid email or password. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    try {
      setIsLoading(true);
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        const customerId = await createStripeCustomer(user.uid, user.email);
        const userData = {
          email: user.email,
          stripeCustomerId: customerId,
          tokens: 0,
          isDispute: false,
          emailVerified: user.emailVerified  // Added for email verification
        };
        await setDoc(userDocRef, userData);
        if (!user.emailVerified) {           // Added for email verification
          await sendEmailVerification(user);
          console.log("Verification email sent to Google user");
        }
      }
      setError(null);
      navigate('/text-to-pic');
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const changeColor = () => {
    const nextIndex = (currentColorIndex + 1) % colors.length;
    setCurrentColorIndex(nextIndex);
  };

  const toggleResetPassword = () => {
    setIsResettingPassword(!isResettingPassword);
    setError(null);
  };

  return (
    <div className={styles.heroSection}>
      <div className={styles.heroContent}>
        <h1>🌊 Text to Pic AI</h1>
        <p>Create stunning photos with the power of AI! Use your AI photos wherever you like: whether on social media, in school, for your business, or in your personal life.</p>
        <ul>
          <li>🎉 Create high quality photos in any style</li>
          <li>✨ Generate your photos in multiple screen sizes</li>
          <li>💸 Save more money when creating your content</li>
          <li>🚀 Create content faster with Text to Pic AI</li>
        </ul>
      </div>
      <div className={styles.signupForm}>
        <div className={styles.formContainer}>
          <form onSubmit={handleAuth}>
            {!isLoggedIn && (
              <>
                <input 
                  type="email" 
                  placeholder="Type your email..." 
                  className={styles.emailInput}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {!isResettingPassword && (
                  <input 
                    type="password" 
                    placeholder="Enter your password..." 
                    className={styles.passwordInput}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                )}
              </>
            )}
            <button type="submit" className={styles.createButton} disabled={isLoading}>
              {isLoading ? "Processing..." : (isLoggedIn ? "AI Generator" : (isResettingPassword ? "Reset Password" : "Create your AI Pics +"))}
            </button>
            {error && <p className={styles.errorMessage}>{error}</p>}
          </form>
          {!isLoggedIn && (
            <>
              <div className={styles.orDivider}>or</div>
              <button className={styles.googleSignIn} onClick={signInWithGoogle} disabled={isLoading}>
                <FontAwesomeIcon icon={faGoogle} className={styles.googleIcon} style={{ color: iconColor }} />
                Continue with Google
              </button>
              <p className={styles.loginInfo}>
                By continuing, you agree to our Terms of Service and Privacy Policy
              </p>
              <p 
                style={{ 
                  color: '#777777', 
                  cursor: 'pointer', 
                  textDecoration: 'underline'
                }} 
                onClick={toggleResetPassword}
              >
                {isResettingPassword ? "Back to Login" : "Reset your Password"}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeroSection;