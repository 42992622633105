import React from 'react';
import './WaveTransition.module.css';

const WaveTransition = () => {
  return (
    <div className="wave-container">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none">
        <path fill="url(#gradient)" fillOpacity="1" d="M0,160L1440,160L1440,320L0,320Z">
        </path>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{stopColor: 'rgb(0,0,0)'}} />
            <stop offset="55%" style={{stopColor: 'rgb(0,0,0)'}} />
            <stop offset="60%" style={{stopColor: 'rgba(0,0,0,0.98)'}} />
            <stop offset="65%" style={{stopColor: 'rgba(5,5,5,0.95)'}} />
            <stop offset="70%" style={{stopColor: 'rgba(10,10,10,0.90)'}} />
            <stop offset="75%" style={{stopColor: 'rgba(15,15,15,0.85)'}} />
            <stop offset="80%" style={{stopColor: 'rgba(20,20,20,0.75)'}} />
            <stop offset="85%" style={{stopColor: 'rgba(25,25,25,0.65)'}} />
            <stop offset="90%" style={{stopColor: 'rgba(30,30,30,0.55)'}} />
            <stop offset="95%" style={{stopColor: 'rgba(35,35,35,0.45)'}} />
            <stop offset="97%" style={{stopColor: 'rgba(40,40,40,0.30)'}} />
            <stop offset="100%" style={{stopColor: 'rgba(45,45,45,0)'}} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default WaveTransition;