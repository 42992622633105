import React, { useState } from 'react';
import { signOut, sendEmailVerification } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import { useNavigate } from 'react-router-dom';
import styles from './UserInfo.module.css';

function UserInfo({ user, userDetails }) {
  const navigate = useNavigate();
  const [cooldown, setCooldown] = useState(0);

  const logout = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redirect to homepage after logout
    } catch (err) {
      console.error(err);
    }
  };

  const handleVerifyEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setCooldown(60); // Start 60 second cooldown
      const timer = setInterval(() => {
        setCooldown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (err) {
      console.error(err);
    }
  };

  if (!user || !userDetails) return null;

  return (
    <div className={styles.userInfo}>
      <p className={styles.boldText}>Signed in as: <span>{user.email}</span></p>
      <p className={styles.boldText}>Token Balance: <span>{userDetails.tokens}</span></p>
      {user.emailVerified ? (
        <p className={styles.boldText}>Email Verified: ✅</p>
      ) : (
        <button 
          onClick={handleVerifyEmail} 
          disabled={cooldown > 0}
          className={styles.verifyButton}
        >
          {cooldown > 0 ? `Verify Email (${cooldown}s)` : 'Verify Email'}
        </button>
      )}
      <button onClick={logout} className={styles.logoutButton}>Logout</button>
    </div>
  );
}

export default UserInfo;