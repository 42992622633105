import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { auth, db } from "../../config/firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import styles from './AIImageUpscaler.module.css';

const AIImageUpscaler = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedScale, setSelectedScale] = useState(2);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [upscaledImageUrl, setUpscaledImageUrl] = useState(null);
  const [user, setUser] = useState(null);
  const [userTokens, setUserTokens] = useState(0);
  const [userFlags, setUserFlags] = useState({ isDispute: false, isFraudulent: false, emailVerified: false });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserTokens(userData.tokens);
          setUserFlags({
            isDispute: userData.isDispute || false,
            isFraudulent: userData.isFraudulent || false,
            emailVerified: userData.emailVerified || false
          });
        }
      }
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const getAccountStatusMessage = () => {
    if (userFlags.isFraudulent) {
      return <p className={styles.error}>Account suspended due to fraud warning. Please contact support.</p>;
    }
    if (userFlags.isDispute) {
      return <p className={styles.error}>Account suspended due to open dispute. Please contact support.</p>;
    }
    if (!userFlags.emailVerified) {
      return <p className={styles.error}>Please verify email before use.</p>;
    }
    return null;
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles[0]) {
      const file = acceptedFiles[0];
      setSelectedImage(file);
      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);
      setUpscaledImageUrl(null);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp']
    },
    multiple: false
  });

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);
      setUpscaledImageUrl(null);
    }
  };

  const updateUserTokens = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const newTokenBalance = userTokens - 1; // One token per upscale
    await updateDoc(userDocRef, { tokens: newTokenBalance });
    setUserTokens(newTokenBalance);
  };

  const scaleOptions = [2, 4, 6];

  const handleUpscale = async () => {
    if (!selectedImage) return;
    
    if (!user) {
      setError("Please log in to upscale images.");
      return;
    }
    
    if (userFlags.isDispute || userFlags.isFraudulent) {
      setError("Your account has been suspended. Please contact support.");
      return;
    }
    
    if (userTokens === 0) {
      navigate('/tokens');
      return;
    }

    setIsProcessing(true);
    setError(null);
    
    try {
      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);
      
      reader.onload = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upscale-image`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              image: reader.result,
              scale: selectedScale,
              userId: user.uid
            })
          });

          if (!response.ok) {
            throw new Error('Upscale failed');
          }

          const data = await response.json();
          if (data.success && data.output) {
            setUpscaledImageUrl(data.output);
            await updateUserTokens(user.uid);
          }
        } catch (error) {
          setError('Upscale failed. Please try again.');
        } finally {
          setIsProcessing(false);
        }
      };

      reader.onerror = () => {
        setError('Error reading file');
        setIsProcessing(false);
      };
    } catch (error) {
      setError('Error preparing image');
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.upscalerContainer}>
      <div className={styles.header}>
        <h1 className={styles.title}>Upscale Image</h1>
        <div className={styles.tokenBalance}>
          {user ? (
            <>
              <p><strong>Token Balance: {userTokens}</strong></p>
              {getAccountStatusMessage()}
            </>
          ) : (
            <p>Please log in to use the upscaler</p>
          )}
        </div>
      </div>

      <p className={styles.description}>
        Upscale your images with our advanced AI tool
      </p>

      <div {...getRootProps()} className={styles.dropzone}>
        <input {...getInputProps()} />
        <button className={styles.selectButton}>
          Select Image
        </button>
        <p className={styles.dropText}>or drop images here</p>
        {selectedImage && (
          <div className={styles.selectedFile}>
            <p>Selected: {selectedImage.name}</p>
          </div>
        )}
      </div>

      <div className={styles.scaleOptions}>
        {scaleOptions.map((scale) => (
          <button
            key={scale}
            onClick={() => setSelectedScale(scale)}
            className={`${styles.scaleButton} ${selectedScale === scale ? styles.active : ''}`}
            disabled={userFlags.isDispute || userFlags.isFraudulent || !userFlags.emailVerified}
          >
            {scale}x
          </button>
        ))}
      </div>

      {error && <p className={styles.error}>{error}</p>}

      <button
        onClick={handleUpscale}
        disabled={!selectedImage || isProcessing || !user || userFlags.isDispute || userFlags.isFraudulent || userTokens === 0 || !userFlags.emailVerified}
        className={styles.upscaleButton}
      >
        {isProcessing ? 'Processing...' : 'Upscale Image'}
      </button>

      {(previewUrl || upscaledImageUrl) && (
        <div className={styles.previewContainer}>
          {previewUrl && (
            <div className={styles.imageBox}>
              <h3 className={styles.imageTitle}>Original</h3>
              <img 
                src={previewUrl} 
                alt="Original" 
                className={styles.previewImage}
              />
            </div>
          )}
          {upscaledImageUrl && (
            <div className={styles.imageBox}>
              <h3 className={styles.imageTitle}>Upscaled ({selectedScale}x)</h3>
              <img 
                src={upscaledImageUrl} 
                alt="Upscaled" 
                className={styles.previewImage}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AIImageUpscaler;