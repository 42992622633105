import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { auth, db } from "./config/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import HomePage from './components/HomePage';
import PaymentPage from './components/PaymentPage';
import TextToPicPage from './components/TextToPicPage';
import UserPage from './components/UserPage';
import AuthStateHandler from './components/AuthStateHandler';  // Added this import
import ImageToUpscale from './components/ImageToUpscale';

function App() {
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserDetails(userDoc.data());
        }
      } else {
        setUserDetails(null);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <div className="App">
        <AuthStateHandler />  {/* Added this line */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tokens" element={<PaymentPage />} />
          <Route path="/text-to-pic" element={<TextToPicPage />} />
          <Route path="/image-upscaler" element={<ImageToUpscale />} />
          <Route path="/user-settings" element={<UserPage user={user} userDetails={userDetails} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;