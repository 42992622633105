// src/components/sections/FeaturesSection.js
import React from 'react';
import styles from './FeaturesSection.module.css';

const features = [
  { icon: '💎', title: 'Save Time', description: 'Create high quality photos in seconds!' },
  { icon: '💲', title: 'Save Money', description: 'Save more and lower costs for you and your business!' },
  { icon: '📸', title: 'Photo Ready', description: 'Upscale your photos easily with our advanced AI tool!' },

  
  
];

function FeaturesSection() {
  return (
    <section className={styles.featuresSection}>
      <h2>🔥 Make More</h2>
      <div className={styles.featureGrid}>
        {features.map((feature, index) => (
          <div key={index} className={styles.featureCard}>
            <div className={styles.featureIcon}>{feature.icon}</div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
      <h2 className ={styles.cheaperh1}>🤑 For Cheaper</h2>
    </section>
  );
}

export default FeaturesSection;